$code-field-name-font-size: 1.8em;
$code-field-value-font-size: 1.6em;

@mixin responsive-codes {
    // Same breakpoints as for auth-form
    $bp-1:  75em; 
    $bp-2:  56em;  

    font-size: 100%;

    @media only screen and (max-width: $bp-1) {
        font-size: 75%;
    }

    @media only screen and (max-width: $bp-2) {
        font-size: 56%;
    }
}

.codes {
    //@include responsive-auth-form;
    font-family: sans-serif;
    max-height: 20em;
    padding-right: 1em;
    overflow-y: auto;
}

.code {
    &:not(:last-child) {
        margin-bottom: calc(#{$code-field-value-font-size} * 3);
    }
}

.code-token {
    max-width: 38%;
    overflow-wrap: anywhere;

    &.key-token {
        max-width: 100%;
    }
}

.code-discount-expiry {
    display: flex;
    overflow-wrap: normal;
}

.code-title-row {
    display: flex;
    justify-content: space-between;
    &.key-title-row {
        justify-content: center;
        align-items: center;
    }
}

.code-field {
    &:not(:last-child) {
        margin-right: 2em;
    }
    &.key-field {
        display: flex;
    }
}

.code-field-name {
    font-size: $code-field-name-font-size;
    font-weight: bold;
    &.key-field-name {
        margin-right: 0.56em;
    }
}

.code-field-value {
    font-size: $code-field-value-font-size;
    &.key-field-value {
        font-size: $code-field-name-font-size;
    }
}

.code-courses {
    text-align: start;
    margin-top: calc(#{$code-field-value-font-size} * 0.94);
}

.code-course {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    line-height: calc(#{$code-field-value-font-size} * 0.94);
}