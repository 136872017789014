/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Merienda+One&family=Merienda:wght@700&display=swap'); /* https://fonts.google.com/specimen/Indie+Flower */

.font-indie-flower {
  font-family: 'Indie Flower', cursive;
  font-weight: 400;
}

.font-merienda-one {
  font-family: 'Merienda One', cursive;
  font-weight: 400;
}

.font-merienda-bold {
  font-family: 'Merienda', cursive;
  font-weight: 700;
}

.bigger-or {
  font-size: 3vw;
  color: #fff;
}

.error {
    color: red;
}

.score-word {
    color: black !important;
    font-size: 2em !important;
    font-weight: bold;
    &::before {
      content:"(password strength: ";
      font-weight: normal;
    }
    &::after {
      content:")";
      font-weight: normal;
    }
}

.message {
    color: green;
}