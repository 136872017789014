@mixin learning-toc-container {
    .learning-toc-container {
        @content;

        position: absolute;
        top: var(--learning-toc-top);
        left: var(--global-padding);
        width: var(--learning-toc-width);
        height: var(--learning-toc-height);
        padding-top: 1.5em;
    }
}

.learning-toc {
    position: relative;
    // All of the following are for scrolling of .learning-toc-items
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.learning-title {
    --font-size: 2.5;
    font-size: calc(var(--font-size) * 1em);
    font-family: sans-serif;
    text-align: center;
    margin-inline-end: var(--global-padding);
    padding-top: calc(1.5 * var(--corner-button-height) / var(--font-size));
}

.learning-subtitle {
    --font-size: 2;
    font-size: calc(var(--font-size) * 1em);
    font-family: sans-serif;
    font-style: italic;
    text-align: center;
    margin-inline-end: var(--global-padding);
    padding-top: calc(0.8 * var(--corner-button-height) / var(--font-size));
}

.learning-reload {
    display: inline-block;
    font-size: 1em;
    font-style: normal;
    font-weight: bold;
    color: blue;
    cursor: pointer;
    &::before {
        content: "↻"
    }
        
    &:hover {
        transform: scale(1.15,1.1);;
    }
    
    &:active {
        transform: translateY(-0.1em);
        transform: scale(0.9,0.95);
    }
        
    
}

.learning-toc-items {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0.8em;
    flex-grow: 1;
    padding-right: var(--global-padding);
}

.learning-unit {
    display: flex;
    align-items: center;
    margin-inline-end: var(--global-padding);
}

.learning-unit-done-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.7em;
    width: 0.7em;
    flex-shrink: 0;
    border: 2px solid black;
    margin-inline-end: 0.5em;
    cursor: pointer;
}

.learning-unit-done {
    color: green;
    font-size: 1.5em;
    padding-bottom: 0.5em;
    padding-left: 0.1em;
}

.learning-unit-partial {
    z-index: -1; // to prevent it from intercepting clicks due to large vertical size.
    color: gray;
    font-size: 3em;
    padding-top: 0.03em;
}

.learning-unit-attachment-dropdown {
    position: relative;
    &:hover {
        cursor: pointer;
    }
}

// Needed to avoid inheriting width from parent
.learning-unit-attachment-dropdown-items {
    z-index: 1; // to cover the attachment icon below
    display: none;
    position: absolute;
    width: var(--learning-toc-width); // enough for sure
}

.learning-unit-attachment-dropdown-items-content {
    display: inline-block;
    max-width: calc(0.75 * var(--learning-toc-width));

    background-color: white;
    margin-inline-start: -1.5em;
    padding-inline-start: 2em;
    padding-inline-end: 2em;
    border: 1px dashed gray;
    border-radius: 1em;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}

.learning-unit-attachment {
    display: flex;
    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
    &:hover {
        color: blue;
    }
}

.learning-unit-attachment-link {
    text-decoration: none;
    color: inherit;
}

.learning-unit-icon-title {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.learning-content-container { 
    position: absolute;
    top: var(--learning-content-top);
    right: var(--global-padding);
    width: var(--learning-content-width);
    height: var(--learning-content-height);
}

.learning-content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: inherit;
}
.learning-player-container {
    display: flex;
    justify-content: center;
}


.react-player {
    width: var(--learning-player-width) !important;
    height: var(--learning-player-height) !important;
}
