@keyframes fade-in {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animation-fade-in-4s {
  animation: fade-in 4s ease-in;
}

@keyframes from-left {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }

  50% {
    transform: translateX(2vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0vw);
  }
}

.animation-from-left {
  animation: from-left 1.5s ease-in;
}

@keyframes from-right {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }

  50% {
    transform: translateX(-2vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0vw);
  }
}

.animation-from-right {
  animation: from-right 1.5s ease-in;
}
