// IMAGES
$img-folder: '../../img/';
$me-logo-image: $img-folder + 'me-logo.jpeg';
$background-image: $img-folder + 'nick-morrison-FHnnjk1Yj7Y-unsplash.jpg';
//$background-image: "https://www.freegreatpicture.com/files/147/18380-hd-color-background-wallpaper.jpg";
$background-image-with-gradient: 
    linear-gradient(
        to right top, 
        rgba(248, 12, 12, 0.235), 
        rgba(17, 226, 128, 0.235)),
        url($background-image);
$title-background-image: $img-folder + 'sea-pattern.jpg'; // https://pixabay.com/photos/water-sea-caribbean-background-1330252/
//$title-background-image: $img-folder + 'sea-pattern-dark.jpg';