.tab {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0.05em 0.25em;
  font-size: 3em;
  color: black;
  border-radius: 10% 10% 0 0 / 40%;

  &-active {
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      box-shadow: 0.2em -0.1em 0 0 rgba(0,0,0,0.6);
      transform: skew(30deg, 0);
    }
  }

  &-inactive {
    color: rgba(gray, 0.4);
    cursor: pointer;
    
    &:hover::before { // note the order
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      box-shadow: 0.2em -0.1em 0 0 rgba(0,0,0,0.6);
      transform: skew(30deg, 0);
    }
  }
}