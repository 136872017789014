.please-add-access {
    font-size: 3vw;
    text-align: center;
}

.courses {
    width: 85vw;
    overflow-y: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-nav {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.toast-container {
    display: inline-block;
    font-size: 2rem;
    word-break: break-word;
    word-wrap: normal;
    text-align: center;
    top: var(--global-padding);
    padding: 0;

    // same breakpoint as in react-toastify/scss/_toastContainer.scss,
    // where they set width: 100vw
    @media only screen and (max-width: 30em) {
        left: var(--global-padding);
        right: var(--global-padding);
        width: calc(100vw - 2*var(--global-padding));
        font-size: 1.5rem;
    }
    & svg {
        transform: scale(1.5);
    }
}

@mixin user-and-dropdown {
    .user-and-dropdown {
        @content;
        display: flex;
    }
}

@mixin dashboard-nav-user {
    .dashboard-nav-user {
        @content;
        @media only screen and (max-width: 32em) {
            display: none;
        }
        line-height: 1.5em;
        letter-spacing: 0.15em;
        font-family: Comic Sans MS, Comic Sans, cursive;
        margin-right: 0.5em;
        text-align: center;
    }
}

.dashboard-nav-bars {
    font-size: 4em;
    display: inline-block;
    text-align: right;
}

.dashboard-nav-dropdown {
    position: relative;
    float: right;
    height: 3em;
    z-index: 2;
        // The main content is centered on screen, so has top:0.
        // Also, react-toastify interferes.
    cursor: pointer;
    &:hover .dashboard-nav-dropdown-items {
        display: block;
    }
}

.dashboard-nav-dropdown-items {
    display: none;
    background-color: white;
    padding-left: 1em;
    position: absolute;
    min-width: 20em;
    right: 0;
    font-family: Comic Sans MS, Comic Sans, cursive;
    
    &>div {
        font-size: 2em;
        line-height: 2em;
        border-bottom: 1px solid black;
        &:hover {
            text-shadow: 2px 2px lightgrey;  
            font-weight: bold;    
            border-bottom: 2px solid green;  
        }
    }
}
