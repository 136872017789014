.receipts-courses {
    text-align: start;
}

.receipts-course-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.receipts-course {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    line-height: 1.33em;
    font-size: 3em;
}

.receipt-container {
    left: var(--global-padding);
    width: calc(100vw - 2 * var(--global-padding));
    height: calc(100vh - 2 * var(--global-padding) - 1.2 * var(--logo-corner-height));
}