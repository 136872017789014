/* Reset */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
}

html {
  /* 
    Root font size, which will become 1rem throughout.
    The browser default value is 16px. So, we make it 10px, so easy to translate all px to rem.
    This will make the site responsive to scaling in browser.
    Also can scale the whole website by changing this value.
  */ 
  font-size: 62.5%;
  overflow-y: hidden;
}

*:focus {
    outline: none;
}

.App {
  height: 100vh;
  padding: var(--global-padding);
  position: relative;
}