$feedback-margin-size: 0.13em;

@mixin feedback-container {
    .feedback-container {
        @content;

        position: absolute;
        padding : inherit;
        width   : 1.67em;
        height  : 100%;
        right   : 0;
        top     : 0;

        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        z-index        : 1;

        &.learning {
            top: var(--learning-feedback-container-top);
            height: var(--learning-feedback-container-height);
        }
    }

    .feedback {
        position        : relative;
        background-color: blue;
        width           : 5em;
        margin-top: 2.5em;
        margin-bottom: 2.5em;
        line-height     : 1.2em;
        color           : white;
        text-align      : center;
        border-radius   : 0.67em 0.67em 0 0;
        text-shadow     : 1px 1px black;
        cursor          : pointer;
        transform       : rotate(-90deg);
        transform-origin: 50% 50%;

        &.question {
            background-color: green;

            &::after {
                border: 1px solid green;
            }
        }


        &:hover {
            transform       : rotate(-90deg) scale(1.05, 1.15);
            transform-origin: 50% 50%;
        }

        &:active {
            transform       : rotate(-90deg) scale(1.00, 1.00) translate(0, 0.1em);
            transform-origin: 50% 50%;
        }

        &:after {
            content      : '';
            position     : absolute;
            right        : -$feedback-margin-size;
            left         : -$feedback-margin-size;
            top          : -$feedback-margin-size;
            bottom       : 0;
            border       : 1px solid blue;
            border-radius: 0.67em 0.67em 0 0;
        }
    }
}