.btn {
  display: inline-block;
  text-decoration: none;
  padding: 0.375em 1em;
  border-radius: 10% / 40%;
  transition: all .2s;
  font-size: 4em;
  cursor: pointer;
  color: #fff;

  &:hover {
    transform: translateY(-0.075em);
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.6);
  }

  &:active {
    transform: translateY(-0.025em);
    box-shadow: 0 0.125em 0.25em rgba(0,0,0,0.6);
    transform: scale(0.9,0.95);
  }

  &-primary {
    background-color: rgba(blue, 0.3);
  }

  &-green {
    background-color: rgba(green, 0.3);
  }

  &-purple {
    background-color: rgba(purple, 0.3);
  }
}