// Responsiveness
// https://stackoverflow.com/questions/14447477/passing-css-propertyvalue-as-sass-mixin-argument

// Folding based on indentation:
// https://www.reddit.com/r/vscode/comments/qidaq5/comment/hincokw/?utm_source=share&utm_medium=web2x&context=3

:root {
    --global-padding: 1rem;
    --welcome-clip: 75%;

    --corner-button-width: 1em;
    --corner-button-height: 1em;

    --logo-corner-initial-width: 44rem;
    --logo-corner-initial-height: 9.28rem;
    --logo-corner-width: var(--logo-corner-initial-width);
    --logo-corner-height: var(--logo-corner-initial-height);

    --learning-toc-top: calc(var(--logo-corner-height) + var(--global-padding));
    --learning-toc-portrait-top: 70vh;
    --learning-toc-width: calc(0.95 * var(--logo-corner-width));
    --learning-toc-height: 
        calc(100vh - var(--logo-corner-height) - 2*var(--global-padding));

    --learning-content-top: 0;
    --learning-content-portrait-top: calc(var(--logo-corner-height) + 2*var(--global-padding));
    --learning-content-width:
        calc(100vw - var(--learning-toc-width) - 2*var(--global-padding));
    --learning-content-height: 100vh;
    --learning-player-width: 
        calc(0.85 * var(--learning-content-width));
    --learning-player-height-logo-limit-raw:
        calc(
            100vh - 
            (1.2 * var(--logo-corner-height) + 2*var(--global-padding)));
    --learning-player-height-logo-limit:
        var(--learning-player-height-logo-limit-raw);
    --learning-player-height: 
        min(calc(9.0/16.0 * var(--learning-player-width)), 
            var(--learning-player-height-logo-limit));

    --learning-feedback-container-top: 0;
    --learning-feedback-container-height: 100vh;
}

@media only screen and (max-width: 30em) {
    :root {
        --global-padding: 0.5rem;
    }
}

// Responsive global vars
$global-vertical-bp: 43em;

// Responsive Welcome 
    $welcome-breakpoints: (
        bp-1: 100em, // 1600px
        bp-2:  75em, // 1200px
        bp-3:  60em, // 960px
        bp-4:  45em, // 720px
        bp-5:  35em, // 560px
        bp-6:  25em, // 400px
        bp-7:  20em, // 320px
    );
    @mixin welcome-media-query($bp, $font-size) {
        @media only screen and (
            max-width: map-get($welcome-breakpoints, $bp)) {
            @include welcome {
                font-size: $font-size;
            }
        }
    }
    @mixin responsive-welcome {
        @include welcome {font-size: 100%;}
        @include welcome-media-query(bp-1, 80%);
        @include welcome-media-query(bp-2, 60%);
        @include welcome-media-query(bp-3, 48%);
        @include welcome-media-query(bp-4, 36%);
        @include welcome-media-query(bp-5, 27%);
        //@include welcome-media-query(bp-6, 21%);
        //@include welcome-media-query(bp-7, 19%);
    }
    @media only screen and (max-width: map-get($welcome-breakpoints, bp-6)) {
        :root {
            --welcome-clip: 100%;
        }
    }

    @include responsive-welcome;

// Responsive logo-corner, user-and-dropdown, dashboard-nav-user, feedback-container, learning-toc-container.
    $logo-corner-breakpoints: (
        wbp-1: 55em, // 880px
        wbp-2:  22.5em, // 360px
        vbp1:  $global-vertical-bp,
        vbp2:  24em,
        wbp-both: 30em,
        vbp-both: 25em,
    );
    $logo-corner-initial-font-size: 40%;
    $user-and-dropdown-initial-font-size: 100%;
    $dashboard-nav-user-initial-font-size: 200%;
    $feedback-container-initial-font-size: 300%;
    $learning-toc-container-initial-font-size: 100%;
    
    @mixin logo-corner-media-query-includes($font-factor) {
        @include logo-corner {
            font-size: calc(
                #{$logo-corner-initial-font-size} * #{$font-factor});
        }
        :root {
            --logo-corner-width: calc(
                var(--logo-corner-initial-width) * #{$font-factor});
            --logo-corner-height: calc(
                var(--logo-corner-initial-height) * #{$font-factor});
        }
        @include user-and-dropdown {
            font-size: calc(
                #{$user-and-dropdown-initial-font-size} * 
                #{$font-factor});
        }
        @include dashboard-nav-user {
            font-size: calc(
                #{$dashboard-nav-user-initial-font-size} * 
                #{$font-factor});
        }
        @include feedback-container {
            font-size: calc(
                #{$feedback-container-initial-font-size} * 
                #{$font-factor});
        }
        @include learning-toc-container {
            font-size: calc(
                #{$learning-toc-container-initial-font-size} * 
                #{$font-factor});
        }
    }
    @mixin logo-corner-media-query($type, $bp, $font-factor) {
        @media only screen and (
            #{$type}: map-get($logo-corner-breakpoints, $bp)) {
            @include logo-corner-media-query-includes($font-factor);
        }
    }
    @mixin logo-corner-media-query-both-types($wbp, $hbp, $font-factor) {
        @media only screen and (max-width: map-get($logo-corner-breakpoints, $wbp)) and  (max-height: map-get($logo-corner-breakpoints, $hbp)){
            @include logo-corner-media-query-includes($font-factor);
        }
    }
    @mixin responsive-logo-corner {
        @include logo-corner {font-size: $logo-corner-initial-font-size;}
        @include user-and-dropdown {
            font-size: $user-and-dropdown-initial-font-size;}
        @include dashboard-nav-user {
            font-size: $dashboard-nav-user-initial-font-size;}
        @include feedback-container {
            font-size: $feedback-container-initial-font-size;}
        @include learning-toc-container {
            font-size: $learning-toc-container-initial-font-size;}
        @include logo-corner-media-query("max-height", vbp1, 0.8);
        @include logo-corner-media-query("max-height", vbp2, 0.7);
        @include logo-corner-media-query("max-width", wbp-1, 0.67);
        @include logo-corner-media-query("max-width", wbp-2, 0.571);
        @include logo-corner-media-query-both-types(wbp-both, vbp-both, 0.5);
    }
    @include responsive-logo-corner;

// Responsive auth-form
    $auth-form-bp: 55.6em; // 90% of 80em

    @mixin auth-form-media-query(
        $type, $bp, $font-size: null, $width: null) {
        
        @media only screen and (#{$type}: $bp) {
            @include auth-form {
                @if ($font-size) {
                    font-size: $font-size;
                }
                @if ($width) {
                    width: $width;
                }
            }
        }
    }
    @mixin responsive-auth-form {
        @include auth-form {font-size: 100%; width: 80em;}
        @include auth-form-media-query(
            "max-height", $global-vertical-bp, $font-size: 0.9em);
        @include auth-form-media-query(
            "max-width", $auth-form-bp, $font-size: 0.6em);
        @include auth-form-media-query(
            "max-width", $auth-form-bp, $width: 90vw);
    }
    @include responsive-auth-form;

// Responsive small-card
    $small-card-breakpoints: (
        wbp-1: 55em, // 880px
        wbp-2:  22.5em, // 360px
        vbp:  $global-vertical-bp,
    );

    @mixin small-card-media-query(
        $type, $bp, $font-size: null, $width: null) {
        
        @media only screen and (
            #{$type}: map-get($small-card-breakpoints, $bp)) {
            @include small_card {
                @if ($font-size) {
                    font-size: $font-size;
                }
                @if ($width) {
                    width: calc(#{$width} + 2 * var(--global-padding));
                }
            }
        }
    }
    @mixin responsive-small-card {
        @include small_card {
            font-size: 100%; 
            width: calc(32em + 2 * var(--global-padding));
        }
        @include small-card-media-query(
            "max-width", wbp-1, $font-size: 80%);
        @include small-card-media-query(
            "max-height", vbp, $font-size: 80%);
        @include small-card-media-query(
            "max-width", wbp-2, $font-size: 66.7%);
    }
    @include responsive-small-card;


// Responsive card
    $card-breakpoints: (
        wbp-1: 55em, // 880px
        wbp-2:  27em, // 360px
        vbp:  $global-vertical-bp,
    );

    @mixin card-media-query(
        $type, $bp, $font-size: null, $width: null) {
        
        @media only screen and (
            #{$type}: map-get($card-breakpoints, $bp)) {
            @include card {
                @if ($font-size) {
                    font-size: $font-size;
                }
                @if ($width) {
                    width: $width;
                }
            }
        }
    }
    @mixin responsive-card {
        @include card {font-size: 100%; width: 40em;}
        @include card-media-query(
            "max-width", wbp-1, $font-size: 80%);
        @include card-media-query(
            "max-height", vbp, $font-size: 80%);
        @include card-media-query(
            "max-width", wbp-2, $font-size: 60%);
    }
    @include responsive-card;

// Responsive layout of learning
@mixin responsive-learning {
    @include learning-toc-container {
        border-right: 1px solid gray;
        border-top: unset;
    }

    // 5em for logo-corner
    @media only screen and (orientation: portrait) {
        :root {
            --learning-toc-top: var(--learning-toc-portrait-top);
            --learning-toc-width: calc(100vw - 2 * var(--global-padding));
            --learning-toc-height: calc(
                100vh - 
                var(--learning-toc-portrait-top) - 
                var(--global-padding));
            
            --learning-content-height: calc(
                100vh - 
                (var(--learning-toc-height) + 
                2*var(--global-padding)));
            --learning-content-width: calc(100vw - 2 * var(--global-padding));
            --learning-player-height-logo-limit:
                calc(
                    var(--learning-player-height-logo-limit-raw) -
                    var(--learning-toc-height));
            
            --learning-feedback-container-top: var(--learning-content-top);
            --learning-feedback-container-height: 
                var(--learning-content-height);
        }
                    
        @include learning-toc-container {
            border-top: 1px solid gray;
            border-right: unset;
        }
    }
}
@include responsive-learning;
