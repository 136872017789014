@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin pseudo-element-background {
    content: ""; 
    background-size: cover;
    background-position: top; /* When resizing the window, the top of image stays attached to top */
    //background-attachment: fixed;
    position: absolute;
    top: 0px;
    right: 0px;
    //bottom: 0px;
    left: 0px;
    height: 100%;
    z-index: -1;
}