$auth-form-padding: 3em;

@mixin auth-form {
    .auth-form {
        @content;
        display: flex; // So the child does not go beyond its flex container
        flex-direction: column;
    }
}

.auth-form-close {
    right: 0.5em;
    &::before {
        content: "╳"
    }
}

.auth-form-up {
    left:0.5em;
    top: 0.1em;
    &::before {
        content: "^"; // "↑" "↻"
        font-size: 3em;
        font-weight: normal;
        line-height: 1em;
    }
}

.auth-form-title-tabs {
    position: sticky;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: $auth-form-padding;
    padding-right: $auth-form-padding;
}

.auth-form-title {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
}


@mixin auth-form-gradient-width-dependent($auth-form-gradient-width) {
    .auth-form-frame-container {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        border-radius: 1em;
        box-shadow: 0 2em 4em rgba(black, 0.4);

        &:before {
            @include pseudo-element-background;
            background-image: $background-image-with-gradient;
            background-position: center;
        }

        &:after {
            @include pseudo-element-background;
            background-image: 
                linear-gradient(
                    100deg,
                    rgba(white, 0.8) 0%,
                    rgba(white, 0.8) $auth-form-gradient-width,
                    transparent $auth-form-gradient-width);
        }
    }

    .auth-form-corner-buttons {
        position: relative;
        left: -$auth-form-padding;
        width: calc(#{$auth-form-gradient-width} + #{$auth-form-padding});
    }

    .auth-form-frame {
        position: relative;
        padding: $auth-form-padding;
        overflow-y: auto;
    }

    .auth-form-body {
        position: relative;
        width: calc(#{$auth-form-gradient-width} - #{$auth-form-padding});

    }
}

@include auth-form-gradient-width-dependent(60%);
@media only screen and (max-width: 30em) {
    @include auth-form-gradient-width-dependent(80%)
}

.auth-input {
    display: block;
    font-size: 2em;
    width: 100%;
    text-align: center;
    border: none;

    &:focus {
        border-bottom: 0.2em solid green;
        box-shadow: 0 0.25em 0.5em rgba(black, 0.6);
    }
}

.auth-input-margin-bottom {
    margin-bottom: 2em;
}

.auth-label {
    display: block;
    font-size: 2.5em;
    width: 100%;
}

.auth-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

@mixin focus-active($transform: false) {
    &:focus {
        outline: green;
        outline-offset: 0.1em;
        outline-style: solid;
        &:active {
            outline: none;
            @if $transform {
                transform: translateY(-0.1em) scale(0.9,0.95);
            }
        }
    }
}

.auth-button {
    background: transparent;
    color: green;
    font-size: 5em;
    filter:drop-shadow(0.04em 0.1em 0.02em rgba(black, 0.8));
    border: none;

    &:hover {
        transform: scale(1.1, 1.05);
        cursor: pointer;
    }

    @include focus-active($transform: true);
}

.auth-button-float {
    background: transparent;
    position: absolute;
    right:0;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 2em;
    border: none;
    max-width: 40%;
    word-wrap: break-word;

    @include focus-active();
}