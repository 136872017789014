@mixin welcome {
  .welcome {
    @content;
    
    height: 100%;
    position: relative; /* So the children's position is relative to the header. */
    clip-path: polygon(0% 0%, 100% 0%, var(--welcome-clip) 100%, 0% 100%);

    &::before { // to apply opacity to the background image
                    // without applying it to all children.
      @include pseudo-element-background;
      background-image: $background-image-with-gradient;
    }
  }
}