.rtl {
    direction: rtl;
}

.tight-box {
    width      : 1px;
    white-space: nowrap;
}

.centered-content {
    text-align: center;
}

.right-content {
    text-align: right;
}

// Horizontal centering of absolutely positioned
@mixin center-absolute() {
    left        : 0;
    right       : 0;
    margin-left : auto;
    margin-right: auto;
}

/* This may result in a problem.
See https://stackoverflow.com/questions/69147616/centering-makes-the-table-narrow */
.center-box {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}

.center-box-flex {
    position       : absolute;
    inset: var(--global-padding);
    display        : flex;
    flex-direction : column;
    align-items    : center;

    &.justify-content {
        justify-content: center; // for <Center>
    }

    &.below-logo { // for CenterBelowLogo
        top: calc(1.2 * var(--logo-corner-height) + var(--global-padding));
        // CodePend: https://codepen.io/mgoldenb/pen/WNZxXBj?editors=1100
        // StackOverflow: https://stackoverflow.com/q/70286729/2725810

        .auto-margin {
            margin: auto;
            box-sizing: content-box;
            display: flex;
            max-height: 100%;
            padding-bottom: calc(1.2 * var(--logo-corner-height));
        }
    }
}



.margin-bottom-s {
    margin-bottom: 1em;
    @include clearfix;
}

.margin-bottom-m {
    margin-bottom: 4em;
    @include clearfix;
}

.negative-margin-top-m {
    margin-top: -4em;
    @include clearfix;
}

.margin-bottom-l {
    margin-bottom: 10em;
    @include clearfix;
}

.margin-right-s {
    margin-right: 1em;
    @include clearfix;
}

.margin-right-m {
    margin-right: 4em;
    @include clearfix;
}

.scale-down-m {
    transform: scale(0.7);
}

.scale-down-l {
    transform: scale(0.4);
}