.col-me-logo {
  float:left;
  margin-right: 2em;

  img {
    height: 23.2em;
    border-radius: 10%;
  }
}

.col-title {
  float:right;
  white-space: nowrap;
  width: 87em;
}

.title-main {
  display: block; /* A block-level element always starts on a new line and takes up the full width available (stretches out to the left and right as far as it can).*/
  font-size: 12em;
  line-height: 1.0;
  letter-spacing: calc(8em/12);
  margin-bottom: calc(6em/12);
  
  // The background of letters
  background-image: url($title-background-image); 
  -webkit-background-clip: text;
  color:transparent;
}

.title-secondary {
  display: block;
  color: #fff;
  font-size: 4.8em;
  line-height: 0.8;
}

@mixin logo-corner {
  .logo-corner {
    @content;

    $logo-corner-border-radius-x: calc(var(--logo-corner-width) * 0.2);
    $logo-corner-border-radius-y: calc(var(--logo-corner-height) * 1.5);

    background-image: $background-image-with-gradient;
    background-size: 82%;
    background-position: right;
    background-repeat: no-repeat;
    border-radius: $logo-corner-border-radius-x / $logo-corner-border-radius-y;
    width: var(--logo-corner-width);
    height: var(--logo-corner-height);
  }
}