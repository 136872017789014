$card-back-background-color: lightskyblue;
$card-padding:1.5em;

@mixin small_card {
    .small-card {
        @content;
        
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 2em;
        margin-bottom: 2em;
        padding: var(--global-padding);
        border-radius: 1em;
        font-family: sans-serif;
        &:hover {
            transform: translateY(-0.3em) scale(1.05);
            box-shadow: 0.5em 0.5em 1em 0em rgba(0,0,0,0.6);
            cursor: pointer;
        }
        &:active {
            transform: translateY(-0.1em) scale(0.95);
        }
        & .card-title {
            font-size: 1.6em;
        }
        & .card-subtitle {
            font-size: 1.2em;
        }
    }
}

.card-image {
    position: relative; // for attribution
    width: 32em;
    height: 18em;
    background-size: cover;
    background-position: bottom;
    //background-repeat: no-repeat;
}

.image-attribution {
    position: absolute;
    z-index: 1;
    background-color: rgba(white, 0.7);
    bottom: 0;
    inset-inline-end: calc(0.5 * var(--global-padding));
    font-size: 1em;
    &.global-padding {
        bottom: var(--global-padding);
        inset-inline-end: var(--global-padding);
    }
}

.corner-button {
    display: inline-block;
    color: gray;
    font-size: 1.0em;
    text-align: center;
    font-weight: bold;
    position: absolute;
    width: var(--corner-button-width);
    height: var(--corner-button-height);
    cursor: pointer;
    z-index: 100;
    top:0.5em;
}

@mixin card-side() {
    max-height: 
        calc(100vh - 2 * var(--global-padding) - var(--logo-corner-height));
    overflow-y: auto;
    overflow-x: hidden;

    padding: $card-padding;
    border-radius: 1em;
    box-shadow: 0 1em 2em rgba(black, 0.4);
    backface-visibility: hidden;

    transition: all 1s;

    & > *:not(:last-child) {
        margin-bottom: 2em;
    }
}

@mixin card {
    .card {
        @content;

        display: inline-block;
        perspective: 150em; // just a big number, rotation can be seen well.
        position: relative;
        font-family: Snell Roundhand, cursive;

        &-face {
            @include card-side();
            background: whitesmoke;

            &.face-flipped {
                transform: rotateY(-180deg);
                &.rtl {
                    transform: rotateY(180deg);
                }
            }
        }

        &-back {
            @include card-side();
            position: absolute;
            top: 0;
            left: 0;
            background: $card-back-background-color;
            transform: rotateY(180deg);

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &.back-flipped {
                transform: rotateY(0);
                &.rtl { // Needed to add specificity.
                    transform: rotateY(0);
                }
            }
            &.rtl {
                transform: rotateY(-180deg);
            }
            &.relative-position {
                position: relative;
            }
        }
    }
}

.card-title-subtitle {
    text-align: start;
    margin-inline-start: 2em;
    & > * {
        margin-top: 0.5em;
    }
}

.card-title {
    font-size: 2em;
    font-weight: bold;
}

.card-subtitle {
    font-size: 1.5em;
    font-style: italic;
}

.card-description {
    font-size: 1.5em;
    text-align: justify;

    & > p {
        margin-bottom: 0.33em;
    }

    & > ul, & > ol {
        margin-inline-start: 2em;
        margin-bottom: 0.5em;
    }
}

.card-proceed {
    color: gray;
    font-size: 1.3em;
    text-align: end;
}

//----------------- Back -------------------

.card-title-back {
    position:sticky;
	top:0;
    background-color: $card-back-background-color;
    font-size: 2.5em;
    font-family: Comic Sans MS, Comic Sans, cursive;
    //font-weight: bold;
}

.card-title-and-features {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 13em;
}

.card-features {
    margin-top: 1.8em;
    overflow-y: auto;
    flex-grow: 1;
}

.card-feature-group-top-margin {
    margin-top: 2em;
}

@mixin card-feature-container() {
    display: flex; // Icon and feature on same line.
    align-items: center;
    max-width: calc(85% - 2em);

    // https://stackoverflow.com/a/15604013/2725810
    &:not(:last-child) { 
        margin-bottom: 0.5em;
    }
}

.card-feature-container-left {
    @include card-feature-container();
}

.card-feature-container-right {
    margin-inline-start: 15%;
    @include card-feature-container();
}

.card-feature {
    margin-inline-start: 0.67em; // Space between icon and feature.
    font-size: 1.5em;
    overflow-wrap: break-word;
    font-family: Comic Sans MS, Comic Sans, cursive;
}

.card-feature-image {
    height: 4em;
}

/*
.price-and-buttons {
    padding: inherit;
}
*/

.composite-price {
    position: relative;
    margin-top: 1em;
    //border: 1px solid black;
}

.price-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.price-text {
    font-size: 2em;
}

.price {
    font-family: Comic Sans MS, Comic Sans, cursive;
    font-size: 3em;
    color: #2D882D;
}

.original-price {
    font-family: Comic Sans MS, Comic Sans, cursive;
    font-size: 2em;
    font-weight: bold;
}

.projected-text {
    font-size: 1.5em;
    text-align: center;
    margin-top: 0.33em;
}

.card-button-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2em;
}

.card-button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    padding: 0.467em 0.67em;
    height: 1.67em;
    min-width: 6.67em;
}

.refund-policy {
    text-align: start;
    margin-top: 2em;
}

.refund-policy-title {
    font-weight: bold;
    font-size: 2em;
}

.refund-policy-item {
    display: flex;
    text-align: start;
    margin-top: 0.33em;
    font-size: 1.5em;
}

.refund-policy-item-icon {
    margin-inline-end: 0.5em;
}

//------------------- Financial aid button ----------------------------

$financial-aid-button-scale: 0.8;
.financial-aid-button {
    font-size: calc(1.5em * #{$financial-aid-button-scale});
    padding: calc(0.467em  * #{$financial-aid-button-scale}) calc(1rem  * #{$financial-aid-button-scale});
}

.financial-aid-button-container {
    position: absolute;
    right:0;
    bottom:50%;
    transform: rotate(-90deg) translate(50%, $card-padding);
    transform-origin: 100% 100%;

    & .btn {

        &:hover {
            transform: none;
            box-shadow: none;
        }

        &:active {
            transform: translateY(-0.1em);
            box-shadow: none;
            transform: scale(0.9,0.95);
        }
    }
}

//----------------------- Financial aid component -----------------

.financial-aid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.financial-aid-title {
    font-weight: bold;
    font-size: 2em;
}

.financial-aid-intro {
    text-align: start;
    font-size: 1.5em;
}

.financial-aid-intro-sentence {
    margin-top: 0.5em;
}

.financial-aid-question-container {
    font-size: 1.5em;
    &:not(:first-child) {
        margin-top: 0.67em;
    }
}

.financial-aid-question-q {
    text-align: start;
}

.financial-aid-question-i {
    width: 100%;
    resize: none;
}

.financial-aid-only-once {
    text-align: start;
    font-size: 1.5em;
}

.financial-aid-submit-button-container {
    width: 100%;
    text-align: center;
}
.financial-aid-submit-button {
    font-size: 1.5em;
    padding: 0.467em 0.67em;

    &.btn {
        &:hover {
            transform: none;
            box-shadow: none;
        }

        &:active {
            transform: translateY(-0.067em);
            box-shadow: none;
            transform: scale(0.9,0.95);
        }
    }
}